import {defaultEnvironmentConfigs} from './environment.defaults';

export const environment = {
  ...defaultEnvironmentConfigs,
  ssoEnv: 'UAT',
  apiUrl: 'https://ptest-scwai-logbook-api.scw.ai',
  ssoUrl: 'https://ptest-scwai-logbook-api.scw.ai',
  appInsights: {
    instrumentationKey: 'b333e547-acc9-4ca1-ad70-610a395e32eb',
    cloudRole: 'logbook-angular-ptest-scwai',
  },
  idleWarningTimeOutInSeconds: 42600,
  idleLogoutTimeOutInSeconds: 21600,
  keepAliveTimeOutInSeconds: 31600,
};
